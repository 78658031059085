import React from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";

const BannersEdit = () => {

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="Container ">
          <div className="row logo-columnimg">
            <div className="col-lg-4 banner-box">
              <div className="logo-insidebox">
                <div>
                  <h5>Home Banner</h5>
                  <img
                    id="blah"
                    src="http://placehold.it/180"
                    alt="your logo"
                    className="mt-3"
                  />
                </div>
                <div className="pt-2">
                  <h5>Change</h5>
                  <input
                    type="file"
                    className="pt-3"
                  />
                  
                </div>
                <button type="submit" className="btn btn-success">
                  Update
                </button>
              </div>
            </div>
            <div className="col-lg-4 banner-box">
              <div className="logo-insidebox">
                <div>
                  <h5>Banner 2</h5>
                  <img
                    id="blah"
                    src="http://placehold.it/180"
                    alt="your logo"
                    className="mt-3"
                  />
                </div>
                <div className="pt-2">
                  <h5>Change</h5>
                  <input
                    type="file"
                    className="pt-3"
                  />
                  
                </div>
                <button type="submit" className="btn btn-success">
                  Update
                </button>
              </div>
            </div>
            <div className="col-lg-4 banner-box">
              <div className="logo-insidebox">
                <div>
                  <h5>Banner 3</h5>
                  <img
                    id="blah"
                    src="http://placehold.it/180"
                    alt="your logo"
                    className="mt-3"
                  />
                </div>
                <div className="pt-2">
                  <h5>Change</h5>
                  <input
                    type="file"
                    className="pt-3"
                  />
                  
                </div>
                <button type="submit" className="btn btn-success">
                  Update
                </button>
              </div>
            </div>
            <div className="col-lg-4 banner-box">
              <div className="logo-insidebox">
                <div>
                  <h5>Banner 4</h5>
                  <img
                    id="blah"
                    src="http://placehold.it/180"
                    alt="your logo"
                    className="mt-3"
                  />
                </div>
                <div className="pt-2">
                  <h5>Change</h5>
                  <input
                    type="file"
                    className="pt-3"
                  />
                  
                </div>
                <button type="submit" className="btn btn-success">
                  Update
                </button>
              </div>
            </div>
            <div className="col-lg-4 banner-box">
              <div className="logo-insidebox">
                <div>
                  <h5>Banner 4</h5>
                  <img
                    id="blah"
                    src="http://placehold.it/180"
                    alt="your logo"
                    className="mt-3"
                  />
                </div>
                <div className="pt-2">
                  <h5>Change</h5>
                  <input
                    type="file"
                    className="pt-3"
                  />
                  
                </div>
                <button type="submit" className="btn btn-success">
                  Update
                </button>
              </div>
            </div>
            <div className="col-lg-4 banner-box">
              <div className="logo-insidebox">
                <div>
                  <h5>Change Banner</h5>
                  <img
                    id="blah"
                    src="http://placehold.it/180"
                    alt="your logo"
                    className="mt-3"
                  />
                </div>
                <div className="pt-2">
                  <h5>Change</h5>
                  <input
                    type="file"
                    className="pt-3"
                  />
                  
                </div>
                <button type="submit" className="btn btn-success">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BannersEdit;
